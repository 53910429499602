export default{
  state: {
    is_record: false,
    a_steps: "",
    b_steps: "",
    record_loser: "",
    winner_direction: -1,
  },
  getters: {
  },
  mutations: {
    updateIsRecord(state, is_record) {
      state.is_record = is_record;
    },
    updateSteps(state, data) {
      state.a_steps = data.a_steps;
      state.b_steps = data.b_steps;
    },
    updateRecordLoser(state, loser) {
      state.record_loser = loser;
    },
    updateWinnerDirection(state, diretion) {
      state.winner_direction = diretion;
    }
  },
  actions: {
  },
  modules: {
  }
}