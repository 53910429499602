<template>
  <ContentField> 游戏介绍 </ContentField>
</template>

<script>
import ContentField from "../../components/ContentField.vue";
export default {
  components: {
    ContentField,
  },
  setup() {},
};
</script>

<style scoped></style>
